$(document).ready(function (){

    if ( $('.stage').length && $('#stage_video').length ){

        setTimeout(function (){

            $('.stage-image').addClass('isDisable');
            $('#stage_video').addClass('isEnable');
        },3000);
    }
});


