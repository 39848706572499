var isMobile    = false,
    isChrome    = false,
    isIExplorer = false,
    isFirefox   = false,
    isSafari    = false,
    isOpera     = false;


// *** public functions ***
require('./layout/functions');



// *** helpers ***
//require('./helpers/Cookie_session/js.cookie.min');          //  Cookie
//require('./helpers/Cookie_session/jquery.session');         //  Session
require('./helpers/OwlCarousel/owl.carousel.min');          //  Owl Carousel
//require('./helpers/FancyBox/jquery.fancybox.min');          //  Fancy Box
//require('./helpers/Validate/jquery.validate');              //  validation
//require('./helpers/Encrypt/aes');                           //  Encrypt
//require('./helpers/DatePicker/pickmeup.min');               //  DatePicker
require('./helpers/jquery.mousewheel.min');                 //  Mousewheel  ( adds cross-browser mouse wheel )


// *** layout ***
require('./layout/smooth-scroll');                          // Smooth scroll ( For internal links )
require('./layout/header');                                 // header js


// *** blocks ***
require('./components/stage');
require('./components/slider');
require('./components/carousel');
require('./components/startPage');
require('./components/single-offer');
require('./components/single-organisation.js');


