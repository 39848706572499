$(document).ready(function (){

    // Slider Members
    $('.members .slider-wrapper').owlCarousel({
        loop:true,
        responsiveClass:true,
        autoplay:true,
        autoplayTimeout:5000,
        autoplayHoverPause:true,
        responsive:{
            0:{
                items:1,
                nav:true
            },
            600:{
                items:3,
                nav:true,
                margin:5
            },
            1000:{
                items:4,
                nav:true,
                loop:true
            }
        }
    })

});


