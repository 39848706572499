$(document).ready(function (){

    $('.offer-other .slider-wrapper').owlCarousel({
        loop:true,
        responsiveClass:true,
        responsive:{
            0:{
                items:1,
                margin: 10,
                nav:true
            },
            600:{
                items:2,
                nav:true,
                margin:5
            },
            1000:{
                items:3,
                margin:25,
                nav:true,
                loop:true
            }
        }
    });

    $(".categoryOffer").change(function (e){
        e.preventDefault();


        // Get Current Category
        var $category = $(this).val(),
            $angeotItems = $(".offer-item");

        console.log($category);

        if ( $category == 'null' ){
            $angeotItems.show();
        }else{

            // First hide all items
            $angeotItems.hide();

            $angeotItems.each(function (){
                if ( $(this).hasClass($category) ){
                    $(this).show();
                }
            });


        }
    });



});
