$(document).ready(function(){

    $(window).scroll(function (){

        if ( $(window).scrollTop() >= 50)
            $(".navbar.header").addClass("fix-header");
        else
            $(".navbar.header").removeClass("fix-header");

    });

});

